
@media only screen and (max-width: 500px) {
    .col_revers_mobile{
        flex-direction: column-reverse !important;
    }
    
}

.css-gmxpvr-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
    padding:  8px !important;
}

.css-ki1hdl-MuiAlert-action{
    padding: 0 !important;
}