.history_h1 {
    width: 100%;
    height: 25px;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 44px;
    /* identical to box height */
    text-align: right;
    letter-spacing: -2px;
    color: #0d152e;
}

.info_delivery_line1_mobile {
    width: 43%;
}

.muirtl-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    max-width: 100%;
}

.muirtl-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    max-width: 100%;
}

.muirtl-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    max-width: initial;
}

.muirtl-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    max-width: 100%;
}

.history_width_line1 {
    width: 100%;
    gap: 8px;
    /* max-width: 570px; */
    max-width: 600px;
    display: flex;
    height: 50px;
    flex-direction: row-reverse;
}

.info_delivery_line1 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 15px 0px;
    /* gap: 55px; */
    /* max-width: 572px; */
    height: 77px;
    /* background: #F8F8FB; */
    width: 100%;
}

.info_delivery_line {
    display: flex;
    flex-direction: row-reverse;
    /* justify-content: space-between; */
    /* align-items: center; */
    padding: 15px 15px 15px 0px;
    /* gap: 55px; */
    /* max-width: 572px; */
    height: 77px;
    /* background: #F8F8FB; */
    width: 100%;
}

.content_history_shipments {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 23px;
    width: 100%;
    /* max-width: 572px; */
    max-width: 626px;
    height: 931px;
}

.head_history_content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 31px;
    width: 168px;
    height: 26px;
}


/*  */

.active {
    /* content: ''; */
    width: 100%;
    height: 0px;
    /* blue */
    background-color: #0060ff;
    border: 1px solid #0060ff;
}

.data_delivery {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    /* max-width: 572px; */
    max-width: 582px;
    height: 947px;
    max-height: 947px;
    overflow: auto;
    padding-right: 5px;
}

.div_data_delivery {
    background: #f8f8fb;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 5px;
    width: 100%;
    /* max-width: 572px; */
    max-width: 650px;
    height: 228px;
    text-align: right;
    border-radius: 5px;
}

[dir="rtl"] .div_data_delivery {
    text-align: left;
}

.div_data_delivery_mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px 0px 0px 0px;
    gap: 3px;
    width: 100%;
    height: 85px;
    background: #f8f8fb;
    box-shadow: 0px 12px 10px rgb(0 0 0 / 11%);
    border-radius: 4px;
    margin-bottom: 5px;
}

.box_info_data_mobile {
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    padding: 15px;
    gap: 9px;
    /* width: 100%; */
    width: 70%;
    height: 305px;
    background: #f3f3f7;
    border-radius: 4px;
}

.info_delivery_server_mobile {
    width: 100%;
    height: 22px;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    text-align: right;
    color: #0d152e;
}

.info_delivery_line1_mobile {
    /* width: 100%; */
    width: 43%;
    display: flex;
    flex-direction: column-reverse;
    /* align-items: flex-end; */
}


/* ::-webkit-scrollbar {
    width: 10px;
    display: none;
    overflow: hidden;
    display: none;
} */

.info_delivery_server {
    width: 100%;
    height: 22px;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #2a304a;
    white-space: nowrap;
}

.time_delivery {
    /* height: 35px; */
    white-space: nowrap;
    color: #818492;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
}

.status_shipping {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 3px;
    background: rgba(13, 21, 46, 0.06);
    border-radius: 3px;
    padding: 2px 7px;
    margin-right: 10px;
    width: 111px;
    height: 26px;
    margin-left: 5px;
}

.flex_address_shipment {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.point {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 5px;
    width: 10.5px;
    height: 10.5px;
    background: #20cb30;
    border-radius: 23.3333px;
}

.back_arrow::before {
    content: "חזרה להוספת משלוח";
    height: 25px;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    text-decoration-line: underline;
    color: #583dff;
    right: 37px;
    top: calc(-2% - 25px / 2);
    position: absolute;
    font-size: 20px;
}

.delete_icon {
    font-family: "Heebo";
    cursor: pointer;
    top: 3px;
    right: 12px;
    left: 10px;
    /* position: absolute; */
}

.img_back_arrow::before {
    content: "";
    height: 20px;
    right: 8px;
    top: calc(-2% - 21px / 2);
    position: absolute;
    width: 23px;
    background-image: url("../images/Arrow\ color.png");
}

.line_courier::before {
    content: "";
    width: 100%;
    height: 0px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    transform: rotate(180deg);
}

.time_delivery_mobile {
    width: 100%;
    height: 26px;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #818492;
    text-align: right;
}

.delivery_server_line1 {
    height: 26px;
    margin-top: 10px;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 700;
    /* font-size: 18px; */
    font-size: 14px;
    line-height: 26px;
    color: #2a304a;
    white-space: nowrap;
    /* text-align: right; */
    width: 96%;
    text-overflow: ellipsis;
    direction: rtl;
}


/* [dir='ltr'] .delivery_server_line1 {
    direction: ltr;
   
} */


/* [dir='rtl']. */


/* @media only screen and (max-width: 500px) {

    .flex_direction_mobile{
        display: flex;
        flex-direction: column !important;
    }
    
    } */

.line_after_address {
    margin-top: 8px;
    /* margin-bottom: 8px; */
    width: 100%;
    height: 0px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    transform: rotate(180deg);
}