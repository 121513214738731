@media only screen and (max-width: 500px) {

.padding_margin{
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

}