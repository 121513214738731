/* .height_{
    display: flex;
flex-direction: column;
align-items: center;
padding: 34px 87px 48px;
gap: 53px;
max-width: 744px;
    width: 50%;
    margin: auto;
height: 1128px;
transform: translate(0,100px);
background: #FFFFFF;
box-shadow: 0px 23px 78px rgba(13, 21, 46, 0.0901961);
border-radius: 4px;
} */

.muirtl-gmoptp-MuiSlider-root .MuiSlider-thumb {
    right: 3% !important;
}

.muirtl-1hmy31-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
    font-weight: 700;
}

.muiltr-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-weight: 700;
}

.muiltr-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-weight: 700;
}

.muiltr-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-weight: 700;
}

.muiltr-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
    direction: rtl;
}

.muiltr-1ixds2g {
    text-align: right;
}

.MuiOutlinedInput-input {
    font-weight: 700;
}

.location_input {
    font-weight: 700 !important;
}

.p_label {
    width: 95%;
    height: 15px;
    font-family: "Heebo";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    /* identical to box height */
    text-align: right;
    color: #4f5976;
}

[dir="rtl"] .p_label {
    text-align: left;
}

.flex_inputs {
    gap: 35px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.height_details_business {
    width: 100%;
    /* height: 1355px; */
    left: 0.5px;
    /* top: 83px; */
    transform: translate(0px, 100px);
    background: #f1f1f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px 87px 48px;
    gap: 53px;
    /* position: absolute; */
    /* width: 744px; */
    max-width: 744px;
    /* max-width: 800px; */
    /* height: 1212px; */
    /* height: 1500px; */
    height: 1700px;
    /* left: 428.5px; */
    /* top: 84px; */
    background: #ffffff;
    box-shadow: 0px 23px 78px rgb(13 21 46 / 9%);
    border-radius: 4px;
    margin: auto;
}

.background_details_business {
    /* height: 1355px; */
    /* height: 1450px; */
    height: 1700px;
    background: #f1f1f6;
}

.form_label_forMobile {
    margin-left: 0 !important;
    margin-right: 0 !important;
    flex-direction: row;
    width: 100%;
    padding: 19px 20px;
    width: 47%;
    box-sizing: border-box;
    height: 63px;
    background: #f2f5ff;
    border: 1px solid #e1e1e8;
    border-radius: 4px;
}

.slider_marg_mobile {
    /* padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto; */
    direction: rtl;
}

[dir="rtl"] .slider_marg_mobile {
    direction: ltr;
    /* margin: auto;
    margin-right: 0 !important;
    margin-left: 25px !important; */
}

.p_marg_bottom {
    margin-bottom: 0 !important;
}

@media only screen and (min-width: 1150px) {
    [dir="ltr"] .illustrationIcon::before {
        content: "";
        position: absolute;
        width: 149.87px;
        height: 153.69px;
        top: -25px;
        left: auto;
        right: -190px;
        background-image: url("../../images/startIllustrationHE.png");
        /* url("../images/startIllustrationHE.png"); */
    }
    .illustrationIcon::before {
        content: "";
        position: absolute;
        width: 166.87px;
        height: 153.69px;
        left: -180px;
        top: -25px;
        background-image: url("../../images/startIllustrationEN.png");
    }
}

@media only screen and (max-width: 500px) {
    .slider_marg_mobile {
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        margin-right: auto;
        margin-left: auto;
    }
    .height_details_business {
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        margin-right: auto;
        margin-left: auto;
    }
}

@media only screen and (max-width: 750px) {
    .p_label {
        width: 100%;
    }
}