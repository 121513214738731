.header_logo_connect {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    align-items: center;

}

.select_arrow > .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-right: 10px !important;
}

.select_navbar {
    width: 225px;
    height: 60px;
    direction: ltr;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

[dir='ltr'] .select_navbar {
    direction: rtl;
}

.MuiList-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 15px 0px; */
    /* gap: 16px; */
    /* position: absolute; */
    /* width: 185px; */
    /* height: 208px; */
    /* left: 75px; */
    /* top: 58px; */
    background: #FFFFFF;
    opacity: 0.9;
    box-shadow: 0px 5px 20px rgb(13 21 46 / 9%);
    border-radius: 4px;
    max-height: 200px;

}

.line::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0px;
    left: 0px;
    top: 68px;
    border: 1px solid #E1E1E8;
}

.span_menulist_line {
    width: 100%;
    height: 0px;

    border: 1px solid rgba(13, 21, 46, 0.14);

}

@media only screen and (max-width: 500px) {

    .header_logo_connect {
        position: fixed;
        z-index: 1000;
        background: #FFFFFF;
        width: 98%;
        align-items: flex-start;

    }

    .MuiList-root {
        gap: 0;
    }

    [dir='ltr'] .select_navbar {
        direction: rtl;
    }

    .select_navbar {
        justify-content: flex-end;
        flex-direction: row !important;
    }
}