@media only screen and (max-width: 500px) {
    .litleAboutTextField {
        width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .flex_header_nameAndPhone {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.invalidTerm {
    /* border: 1px solid #ff0000 */
    color: #ff0000!important;
}