.shipment_tracking {
    display: flex;
    flex-direction: column;
    /* padding: 0px 58px 23px; */
    gap: 43px;
    /* width: 746px; */
    height: 488px;
    background: rgba(255, 255, 255, 0.98);
    border-radius: 4px;
}

.head_shipment {
    width: 346px;
    height: 54px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    font-size: 37px;
    line-height: 54px;
    letter-spacing: -2px;
    color: #0D152E;
}

.box_status {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 572px;
    height: 250px;
}

.line_status {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 0px;
    gap: 17px;
    margin-bottom: 15px;
    width: 572px;
    height: 32px;
}

.line_between {
    width: 25px;
    height: 0px;
    border: 1px solid #F1F1F6;
    transform: rotate(90deg);
}

.span_status_active {
    width: 198px;
    height: 24px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    text-align: right;
    letter-spacing: -0.5px;
    color: #0D152E;
}

.span_status_done {
    height: 24px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.5px;
    color: #0D152E;
}

.span_status {
    width: 206px;
    height: 24px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.5px;
    color: rgba(13, 21, 46, 0.38);
}

.action_status {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 572px;
    height: 78px;
}

.button_status_update {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 270px;
    gap: 10px;
    width: 187px;
    height: 54px;
    background: rgba(88, 61, 255, 0.11);
    border-radius: 4px;
}

.button_status_cancel {
    align-items: center;
    padding: 16px 0px;
    gap: 5px;
    width: inherit;
    width: 365px;
    height: 54px;
    display: inline-flex;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 4px;
}

.span__button_update {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 187px;
    height: 54px;
    background: rgba(88, 61, 255, 0.11);
    border-radius: 4px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    color: #583DFF;
}

.span__button_cancel {
    width: 86px;
    height: 25px;
    white-space: nowrap;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    /* identical to box height */
    text-align: center;
    color: #FF0000;
}

.flex_button_status {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;
    height: 78px;
}

.div_buttons_status {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 30px;
    height: 54px;
}

.label_find_deliver {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    gap: 11px;
    margin-right: 15px;
    width: 317px;
    height: 37px;
    text-decoration: underline;
    cursor: pointer;
    background: #F8F8FB;
    border-radius: 4px;
}

.margin_find_deliver {
    margin-right: 33px;
}

.alert_delete {
    width: 400px;
    height: 120px;
    position: absolute;
    left: 92px;
    top: 105px;
    font-family: 'Heebo' !important;
}


/* @media only screen and (max-width: 800px) {

.shipment_tracking{
    padding: 0rem 1rem;
}
} */

@media only screen and (max-width: 500px) {
    .infoSteps {
        position: relative;
    }
    .box_status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 14px;
        width: 100%;
        /* min-width: 336px; */
        height: 261px;
    }
    .shipment_tracking {
        z-index: 1111;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        width: 100%;
        height: 261px;
    }
    .line_status {
        width: 100%;
    }
    .label_find_deliver {
        width: 100%;
    }
    .head_shipment {
        width: 246px;
        height: 34px;
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 34px;
        /* identical to box height, or 131% */
        text-align: center;
        letter-spacing: -1.24px;
        color: #0D152E;
    }
    .create_new_track_logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* padding: 16px 270px; */
        gap: 10px;
        width: 138px;
        height: 41px;
        border: 1px solid #583DFF;
        border-radius: 4px;
    }
    .span_create_new_track {
        width: 125px;
        height: 22px;
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #583DFF;
        white-space: nowrap;
    }
    .pogo_logo_create_new_track_logo {
        width: 124px;
        height: 37.86px;
    }
    /* 
    [dir='rtl'] .create_new_track_logo::after {

        margin-left: 0px;
        margin-right: 50px;

    } */
    .pogo_logo_flex_img {
        position: absolute;
        top: -33px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        width: 100%;
        background: #FFFFFF;
    }
    .action_status {
        width: 100%;
    }
    .div_buttons_status {
        flex-direction: column;
        height: 180px;
    }
    .line_button_status {
        width: 100%;
    }
    .span__button_update {
        width: 100%;
    }
    .button_status_update {
        width: 100%;
        justify-content: center;
    }
    .button_status_cancel {
        width: 100%;
        justify-content: center !important;
    }
}

@media only screen and (max-width: 650px) {
    /* .shipment_tracking{
    padding-right: 2rem;
} */
}


/* @media (min-width: 576px){
.summmary_container {
    max-width: 100% !important;
}

} */