[dir="ltr"] #back {
    transform: translate(-33px, 45%);

}

#back {
    transform: translate(-185px, 45%);


}

.p_backToDetails {
    width: 394px;
    height: 58px;

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    /* or 161% */

    text-align: center;
    letter-spacing: -0.66px;

    color: #81838C;
}

#detailsId {
    width: 744px;
    height: 341px;
}

#detailsId {
    transform: translate(0%, 26%);
    margin-top: 50px;
}

.btn_backToDetails {
    width: 350px;

    background-color: #583DFF !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;

    height: 62px;
    background: #583DFF;
    border-radius: 4px;
    font-family: 'Heebo' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 29px !important;
    text-align: center !important;

    color: #FFFFFF !important;

}

@media only screen and (max-width: 800px) {
    #coverDetailsId {
        margin-top: -105px;
        width: 100%;
        height: 400px;
        background: none;

    }

    .btn_backToDetails {
        width:100%
    }

    #detailsId {
        width: 470px;
        background: rgba(255, 255, 255, 0.98);
        box-shadow: 0px 23px 78px rgba(13, 21, 46, 0.0901961);
        transform: translate(0%, 26%);
    }

}
.mobile_width_backToDetails {

background: #F1F1F6;
}
@media only screen and (max-width: 500px) {
    .mobile_width_backToDetails {
        /* width: 375px; */
        height: 251px;
        /* min-width: 354px; */
        background: #F1F1F6;
        padding: 0;

    }

    .height_mobile_back {
        height: 387px;
        position: relative;
    }

    .mobile_back {
        /* width: 375px; */
        height: 251px;
        position: relative;
        background: #F1F1F6;
    }

    .margin {
        margin-bottom: 0px;
    }


    .btn_backToDetails {
       
        width: 100%;
        max-width: 350px;
        height: 62px;
    }

    /* .mobile_width_backToDetails{
   
    
  } */

    #coverDetailsId {
        position: absolute;
        top: -84px;
    }

    #detailsId {
        gap: 0;
        width: 100%;
        box-shadow: none;
        background: none;
        align-items: center;
    }
}