.h1About {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 62px;
  /* or 111% */
  /* white-space: nowrap; */
  text-align: right;
  letter-spacing: -2.4px;

  /* Dark */

  color: #18214D;

}
.icons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  white-space: nowrap;

}


.flex_header_about {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  /* padding: 0rem 1.5rem */
}


.img-width {
  width: 100%;
    /* height: 24rem; */
    height: auto;
  border-radius: 22px;
}

[dir="rtl"] .h1About {
  text-align: end;
}

.about {
  /* height: 971px; */
  border-radius: 0px;
  margin-top: 50px;


}

.about_header_p {
  width: 390px;
  height: 78px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  letter-spacing: -0.3px;


  color: #3E4462;


}

.aboutHead {
  /* display: grid;
    justify-content: end; */
  margin-bottom: 20px
}

.textIcon {
  display: flex;

}

[dir="rtl"] .textIconMargin {
  text-align: end;

}

/* @media (min-width: 576px){
  .container, .container-sm {
    max-width: 90%;
  }
} */


@media only screen and (max-width: 800px) {

  .mobile_width {
    width: 100%;
    height: 915px;


  }

  .icons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 34px;
    margin-top: 50px;
    margin-right: 0px;
    width: 100%;
    height: 323px;
    /* padding: 0rem 1rem; */
  }
  .img_width_div{
    height: 20rem;
    /* padding: 0rem 1rem; */
    display: flex;
    align-items: center;


  }

  .img-width{
    width: 100%;
    height: 100%;
  }
 

  .textIconMargin {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
  }

  .h1About {
    font-size: 40px;
    white-space: nowrap;
    line-height: 50px;
  }

  /* .flex_header_about{
    align-items: center;
  } */

}

@media only screen and (max-width: 1200px) {
  .flex_header_about {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1400px) {
  .about_header_p {
    width: 525px;
  }


}


.textIconMargin {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  margin-inline: 10px;
}





@media only screen and (max-width: 500px) {
  .mobile_width{
    height:838px;
  }

  .img_width_div{
    width: 100%;
    height: 15rem;
  
    border-radius: 22px;

  }

  .img-width {
    /* width: 375px; */
    width: 100%;
    height: 100%;
    
    border-radius: 22px;
  }

  .h1About {
    width: 100%;
    height: 74px;
    white-space: wrap;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 37px;
    /* text-align: end; */
    letter-spacing: -1.24px;

    color: #0D152E;

  }

  .flex_header_about {
    /* display: flex; */

    height: 130px;
    flex-direction: column;
    align-items: end;
  }

  .about {
    width: 100%;
    height: 224px;
    margin-top: 0px;
    border-radius: 22px;



  }


  .about_header_p {
    width: auto;
    text-align: right;
    font-size: 16px;
  }

  .aboutHead {
    margin-top: 25px;
    margin-bottom: 0;

  }


 


  
}









